/* OG */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* misc. */

subheading {
	margin-top: -1.25em;
}

/* Players flex container */
.players {
	margin: 0px auto;
  display: flex;
  flex-flow: row wrap;
	justify-content: center;

}

.players section {
	margin: 2% 1% 1% 0%;
	flex: 0 0 190px;
	padding: 15px 10px 15px 10px;
  border: 1px solid;
  text-align: center;
  position: relative;
}


pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #90eaa6;
  border: 1px solid #ccc;
  border-radius: 4px;
}

a.source-link {
	position: absolute;
	font-size: .8em;
	color: #41392f;
	opacity: .8;
	text-decoration: none;
	right: 1em;
	bottom: 1em;
}

a.source-link:hover {
	opacity: 1;
}

/* Player Specific Styles */
.full-control {
  margin-top: -1em;
}

.full-control .toggles label:first-of-type {
  margin-right: 1.5em;
}

.full-control .toggles input {
  margin-left: .5em;
}

.full-control .volume,
.full-control .seek {
  margin-bottom: .5em;
}

.full-control .volume .slider-container,
.full-control .seek .slider-container {
  margin: auto .5em;
}

.slider-container input {
  vertical-align: bottom;
}

.rate {
  margin-bottom: 0.5em;
}
